<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :riskReduce.sync="riskReduce"
          :param="param"
          :height="height"
          @research="research"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checklist-risk',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: 'impr',
      tabItems: [
        { name: 'impr', icon: 'construction', label: 'Punch List', component: () => import(`${'./checklistImpr.vue'}`) },
        { name: 'risk', icon: 'report_gmailerrorred', label: '위험등록부', component: () => import(`${'./checklistRiskRegister.vue'}`) },
      ],
      editable: true,
      listUrl: '',
      riskReduce: {
        imprs: [],
        riskbooks: [],
      }
    };
  },
  watch: {
    'param.searchRiskReduce.flag'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.checklist.riskReduce.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    research() {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: 'riskReduce',
        }
      })
    },
  }
};
</script>
